import React from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import LayoutWithMKMMenu from "./utilities/components/layoutwithmenu";

function EmailService() {
  return (
    <DndProvider backend={HTML5Backend}>
      <LayoutWithMKMMenu />
    </DndProvider>
  );
}

export default EmailService;
