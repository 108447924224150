import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import queryString from "query-string";

import Layout from "./layout";
import Layoutwithoutmenu from "./layoutwithoutmenu";
import routes from "../../routes";
import { apiCall } from "../../utilities/apiCall";
import Overlay from "../../utilities/overlay";
import { decode } from "../commonhooks";

const excludeMenuRoutes = [
  "external",
  "useroptoutform",
  "thankyoupage",
  "managepreferences",
  "userthankspage",
  "sessionout"
];

class LayoutWithMKMMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      showMoreMenu: "hide-element",
      displayEmailStats: false,
      subscriptionsEnabled: false,
      repNotificationsOnly: false,
      isESEnabled: false,
      userHasMKMAccess: null
    };
    this.handleLiClick = this.handleLiClick.bind(this);
    this.collapseEmailStats = this.collapseEmailStats.bind(this);
    this.getClientDetails = this.getClientDetails.bind(this);
  }

  handleLiClick(event, compName, fromChild) {
    // const liClassElem = document.querySelector("li.active");
    // liClassElem.classList.remove("active");
    if (compName == "More") {
      this.setState((prevState) => ({
        showMoreMenu: prevState.showMoreMenu == "hide-element" ? "expandField" : "hide-element"
      }));
    } else {
      const isEmailVerificationStats = compName === "Email Verification Stats";
      this.setState({ showMoreMenu: "hide-element", displayEmailStats: isEmailVerificationStats });
      if (fromChild || isEmailVerificationStats) {
        event.stopPropagation();
      }
    }
  }
  componentDidMount() {
    this.getClientDetails();
  }
  getClientDetails() {
    const values = queryString.parse(window.location.search);
    let clnt = values.clnt;
    this.setState({ isLoading: true });
    apiCall(`/api/getStatus?payload=${clnt}`)
      .then((res) => {
        this.setState({ isLoading: true });
        if (res.status === "success") {
          sessionStorage.setItem("_retryCount_", 0);
          if (res.navigate === "showSetup") {
            //Navigate to setup page and display the message
            const replace = window.location.replace;
            replace("/setupPage");
          } else {
            //console.log(JSON.stringify(res.accessControl));
            window["timeZoneMinutes"] = res.timeZoneMinutes;
            window["estDateOffset"] = res.estDateOffset;
            let accessControlList = res.accessControl;
            sessionStorage.setItem("ACCESS_CONTROL", accessControlList);
            let userAccessData;
            try {
              userAccessData = JSON.parse(decode(accessControlList)) || {};
            } catch (error) {
              console.log(error);
              const replace = window.location.replace;
              replace({
                pathname: "/errorPage",
                state: {
                  message: "Something went wrong. Please try again later."
                }
              });
            }

            const { userHasMKMAccess = false } = userAccessData;
            this.setState({
              subscriptionsEnabled: res.subscriptionsEnabled,
              repNotificationsOnly: res.repNotificationsOnly,
              isESEnabled: true,
              userHasMKMAccess: userHasMKMAccess
            });
          }
        } else {
          const replace = window.location.replace;
          replace({
            pathname: "/errorPage",
            state: {
              message: res.message
            }
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        const replace = window.location.replace;
        replace({
          pathname: "/errorPage",
          state: {
            message: "Something went wrong. Please try again later."
          }
        });
      });
  }
  collapseEmailStats() {
    this.setState({ displayEmailStats: false });
  }

  render() {
    const { repNotificationsOnly, userHasMKMAccess } = this.state;
    return (
      <BrowserRouter>
        <Switch>
          {routes.map((route, index) => {
            return route.uselayout ? (
              !repNotificationsOnly || (repNotificationsOnly && route.repNotification == true) ? (
                route.hasMKMAccessCheck && userHasMKMAccess !== null && !userHasMKMAccess ? (
                  <Route key={route.path} path={route.path} render={() => <Redirect to="/accessDenied" />} exact />
                ) : (
                  <Layout
                    path={route.path}
                    key={route.path}
                    handleLiClick={this.handleLiClick}
                    showMoreMenu={this.state.showMoreMenu}
                    component={route.component}
                    displayEmailStats={this.state.displayEmailStats}
                    collapseEmailStats={this.collapseEmailStats}
                    subscriptionsEnabled={this.state.subscriptionsEnabled}
                    repNotificationsOnly={this.state.repNotificationsOnly}
                    isesEnabled={this.state.isESEnabled}
                    exact
                  />
                )
              ) : (
                <Route
                  key={route.path}
                  path={route.path}
                  render={() => <Redirect to="/setupPage?repNotificationDisable=true" />}
                  exact
                />
              )
            ) : !repNotificationsOnly || (repNotificationsOnly && route.repNotification == true) ? (
              route.hasMKMAccessCheck && userHasMKMAccess !== null && !userHasMKMAccess ? (
                <Route key={route.path} path={route.path} render={() => <Redirect to="/accessDenied" />} exact />
              ) : (
                <Layoutwithoutmenu path={route.path} key={route.path} component={route.component} />
              )
            ) : (
              <Route
                key={route.path}
                path={route.path}
                render={() => <Redirect to="/setupPage?repNotificationDisable=true" />}
                exact
              />
            );
          })}
          {/* <Route path="/" render={() => <Redirect to="/campaignList" />} /> */}
          {this.state.isLoading ? <Overlay /> : null}
        </Switch>
      </BrowserRouter>
    );
  }
}
export default LayoutWithMKMMenu;
