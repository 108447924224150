const excludeMenuRoutes = [
  "useroptoutform",
  "thankyoupage",
  "managepreferences",
  "userthankspage",
  "sessionout",
  "embeddedform",
  "errorpage",
  "setuppage",
  "userthankspage",
  "checkoutpage",
  "previewtemplate",
  "summarynotificationdata",
  "mkmpollcampaign",
  "dynamicformreport",
  "pollingstatsreport"
];
export const apiCall = (
  endpoint,
  method = "GET",
  data = {},
  headers = {},
  cache = "no-cache",
  mode = "cors",
  options = {}
) => {
  const fullUrl = endpoint;
  let _headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json"
  });
  Object.keys(headers).forEach((key) => {
    _headers.append(key, headers[key]);
  });
  let request = {};

  if (method !== "GET") {
    request = new Request(fullUrl, {
      method: method,
      mode: mode,
      cache: cache,
      headers: _headers,
      credentials: "include",
      body: JSON.stringify(data)
    });
  } else {
    request = new Request(fullUrl, {
      method: method,
      mode: mode,
      cache: cache,
      headers: _headers,
      credentials: "include"
    });
  }
  return fetch(request, options)
    .then((res) => {
      if (!res.ok) {
        if (res.status === 301) {
          const currentUrl = window.location.href.toLowerCase();
          let isExternalRoute = excludeMenuRoutes.find((externalRoute) => currentUrl.includes(`/${externalRoute}`));
          if (res.url.includes("getStatus") && !isExternalRoute) {
            let retryCount = sessionStorage.getItem("_retryCount_")
              ? parseInt(sessionStorage.getItem("_retryCount_"))
              : 0;
            if (retryCount < 2) {
              retryCount++;
              sessionStorage.setItem("_retryCount_", retryCount);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              window.location.href = "/sessionout";
            }
          } else if (!isExternalRoute) {
            window.location.href = "/sessionout";
          }
        }
        return Promise.reject(res);
      }
      return res.json();
    })
    .catch((error) => {
      if (error.name === "Abort Error") {
        return;
      }
      console.log("API call Error : ", error);
      console.error(error); //eslint-disable-line no-console
      throw new Error(error.message || " Network request failed");
    });
};

export const mmApiCall = (...args) => {
  const [url, token, ...remainingArgs] = args;
  return apiCall(`api/mm/${url}?token=${token}`, ...remainingArgs);
};
