import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import EmailStats from "../../profileManagement/EmailStats";
import { AdminOnly } from "./AdminOnly";
import { accessControls, REP_NOTIFICATION_NOT_ALLOWED_TOOLTIP } from "../enums";
import Tooltip from "react-tooltip-lite";

const emailServicecompwithurl = [
  {
    displayName: "Campaigns",
    url: "/campaignList",
    imageSource: "Campaigns.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Segmentation",
    url: "/segmentList",
    imageSource: "Segments.svg",
    className: "nav-segment"
  },
  {
    displayName: "Templates",
    url: "/templates",
    imageSource: "Template.svg",
    className: "nav-template"
  },
  {
    displayName: "Forms",
    url: "/forms",
    imageSource: "Forms.svg",
    className: "nav-optin"
  },
  {
    displayName: "Opt-out Forms",
    url: "/OptoutForms",
    imageSource: "Email-Optout.svg",
    className: "nav-optout"
    // adminWrapper: true,
    // actionType: accessControls.optOuts.enableOptouts,
  },
  {
    displayName: "Profiles",
    url: "/profilemanagement",
    imageSource: "icon-profile2.svg",
    className: "nav-profiles"
  },
  {
    displayName: "Workflows",
    url: "/workflowlist",
    imageSource: "workflow.svg",
    className: "nav-workflow"
  },
  {
    displayName: "Landing Pages",
    url: "/landingpages",
    imageSource: "Forms.svg",
    className: "nav-campaign"
  },
  {
    displayName: "More",
    url: "",
    imageSource: "Campaigns.svg",
    className: "nav-more"
  }
  // {
  //   displayName: "Job Dashboard",
  //   url: "/jobdashboard",
  //   imageSource: "Intrest-groups.svg",
  //   className: "nav-interestgroup"
  // }
];
let moreMenuComponents = [
  {
    displayName: "Import Subscribers",
    url: "/ImportSummary",
    imageSource: "Import-Subscribers.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Import Unsubscribers",
    url: "/optoutimportsummary",
    imageSource: "Import-Unsubscribers.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Email Verification Stats",
    url: "",
    imageSource: "Email-Optin.svg",
    className: "nav-campaign",
    EVStats: true
  },
  {
    displayName: "BI Segmentation",
    url: "/segmentListbi?type=bi",
    imageSource: "BI-Segmentation.svg",
    className: "nav-campaign",
    adminWrapper: true,
    isSaOnly: true
  },
  {
    displayName: "Groups",
    url: "/managegroups",
    imageSource: "Intrest-groups.svg",
    className: "nav-interestgroup"
  },
  {
    displayName: "Webhook Setup",
    url: "/webhooklist",
    imageSource: "Forms.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Tags",
    url: "/managetags",
    imageSource: "tag.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Reputation Dashboard",
    url: "/reputationdashboard",
    imageSource: "icon-reputation-dashboard.svg",
    className: "nav-campaign"
    // adminWrapper: true
  },
  {
    displayName: "Spam Traps Dashboard",
    url: "/spamtraps",
    imageSource: "icon-reputation-dashboard.svg",
    className: "nav-campaign",
    adminWrapper: true
  },
  {
    displayName: "Disclaimers",
    url: "/managedisclaimer",
    imageSource: "icon-disclaimers.svg",
    className: "nav-campaign",
    adminWrapper: true
  },
  {
    displayName: "Clients Reputation Summary",
    url: "/clientsreputationsummary",
    imageSource: "icon-clients-reputation-report.svg",
    className: "nav-campaign",
    adminWrapper: true,
    isSaOnly: true
  },
  {
    displayName: "Email DashBoard",
    url: "/recentcampaigns",
    imageSource: "icon-clients-reputation-report.svg",
    className: "nav-campaign",
    adminWrapper: true,
    isSaOnly: true
  },
  {
    displayName: "Scheduler Summary",
    url: "/scheduleSummary",
    imageSource: "icon-clients-reputation-report.svg",
    className: "nav-campaign",
    adminWrapper: true,
    isSaOnly: true
  },
  {
    displayName: "Profile Fields",
    url: "/managefields",
    imageSource: "tag.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Manage Domains",
    url: "/disposabledomains",
    imageSource: "tag.svg",
    className: "nav-campaign"
  },
  {
    displayName: "CYL Dashboard",
    url: "/cyl-dashboard",
    imageSource: "Forms.svg",
    className: "nav-campaign",
    isSaOnly: true,
    adminWrapper: true
  },
  {
    displayName: "CYL Users",
    url: "/users-list",
    imageSource: "Forms.svg",
    className: "nav-campaign",
    isSaOnly: true,
    adminWrapper: true
  },
  {
    displayName: "CYL Domains",
    url: "/domain-stats",
    imageSource: "Forms.svg",
    className: "nav-campaign",
    isSaOnly: true,
    adminWrapper: true
  },
  {
    displayName: "Deleted/Archived Profiles",
    url: "/profileUpdateDeleteList",
    imageSource: "Forms.svg",
    className: "nav-campaign"
  },
  {
    displayName: "File Manager",
    url: "/fileManager",
    imageSource: "FileManager.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Background Jobs",
    url: "/backgroundJobs",
    imageSource: "BgJob.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Enrollment Dashboard",
    url: "/enrollmentDashboard",
    imageSource: "icon-reputation-dashboard.svg",
    className: "nav-campaign",
    adminWrapper: true,
    isSaOnly: true
  },
  {
    displayName: "Website Tags & Subtags Mapping",
    url: "/websitetags-subtags-mapping",
    imageSource: "tag.svg",
    className: "nav-campaign",
    adminWrapper: true,
    isSaOnly: true
  }
];
const subscriptionMenuItems = [
  {
    displayName: "Checkout Forms",
    url: "/checkoutForms",
    imageSource: "Forms.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Checkout Pages",
    url: "/checkoutpages?cp=true",
    imageSource: "Forms.svg",
    className: "nav-campaign"
  },
  {
    displayName: "Subscription Dashboard",
    url: "/subscriptionDashboard",
    imageSource: "icon-reputation-dashboard.svg",
    className: "nav-campaign",
    adminWrapper: true,
    isSaOnly: true
  },
  {
    displayName: "Orders Dashboard",
    url: "/ordersDashboard",
    imageSource: "icon-reputation-dashboard.svg",
    className: "nav-campaign",
    adminWrapper: true,
    isSaOnly: true
  },
  {
    displayName: "Generic Dashboard",
    url: "/genericDashboard",
    imageSource: "icon-reputation-dashboard.svg",
    className: "nav-campaign",
    adminWrapper: true,
    isSaOnly: true
  }
];
const repNotificationAccessibleMenuItems = ["Campaigns", "Workflows"];
function EsSideMenu(props) {
  const moreMenuItems = (cmp, index) => {
    return (
      <li
        key={index}
        onClick={(event) => {
          props.handleLiClick(event, cmp.displayName, true);
        }}
        className={
          props.location.pathname + props.location.search == cmp.url ? "active " + cmp.className : cmp.className
        }
      >
        {cmp.displayName !== "Email Verification Stats" ? (
          props.repNotificationsOnly ? (
            <a style={{ cursor: "not-allowed" }}>
              <Tooltip content={REP_NOTIFICATION_NOT_ALLOWED_TOOLTIP} paddingBottom={20} direction="left">
                <span>
                  <img src={process.env.PUBLIC_URL + "/images/" + cmp.imageSource} />
                </span>
                {cmp.displayName}
              </Tooltip>
            </a>
          ) : (
            <Link to={cmp.url}>
              <span>
                <img src={process.env.PUBLIC_URL + "/images/" + cmp.imageSource} />
              </span>
              {cmp.displayName}
            </Link>
          )
        ) : (
          <Fragment>
            <a>
              <span>
                <img src={process.env.PUBLIC_URL + "/images/" + cmp.imageSource} />
              </span>
              {cmp.displayName}
            </a>
          </Fragment>
        )}
      </li>
    );
  };
  let moreMenu;
  if (props.subscriptionsEnabled == true) {
    moreMenu = moreMenuComponents.concat(subscriptionMenuItems);
  } else {
    moreMenu = moreMenuComponents;
  }
  const mainMenuItems = (esComp, index) => {
    return (
      <li
        key={index}
        onClick={(event) => props.handleLiClick(event, esComp.displayName)}
        className={
          (props.location.pathname == esComp.url ||
          (esComp.displayName == "More" &&
            moreMenu.some((x) => props.location.pathname + props.location.search == x.url))
            ? "active " + esComp.className
            : esComp.className) +
          (props.repNotificationsOnly && !repNotificationAccessibleMenuItems.includes(esComp.displayName)
            ? " disable-text "
            : "")
        }
      >
        {esComp.displayName == "More" ? (
          <React.Fragment>
            {props.repNotificationsOnly && !repNotificationAccessibleMenuItems.includes(esComp.displayName) ? (
              <a style={{ cursor: "not-allowed" }}>
                <Tooltip direction="left" content={REP_NOTIFICATION_NOT_ALLOWED_TOOLTIP} paddingBottom={20}>
                  {esComp.displayName}
                  <i className="fa fa-ellipsis-h" style={{ color: "rgb(74, 226, 226)", cursor: "not-allowed" }} />
                </Tooltip>
              </a>
            ) : (
              <a>
                <i className="fa fa-ellipsis-h" style={{ color: "rgb(74, 226, 226)" }} />
                {esComp.displayName}
              </a>
            )}
            <div
              className={"moremenu " + props.showMoreMenu}
              id="moremenuexpand"
              style={{ maxHeight: "490px", overflow: "auto" }} // fixed max height because Main menu height is constant
            >
              <ul>
                {moreMenu.map((cmp, index) => {
                  return cmp.adminWrapper ? (
                    <AdminOnly key={index} isSaOnly={cmp.isSaOnly}>
                      {" "}
                      {moreMenuItems(cmp, index)}
                    </AdminOnly>
                  ) : (
                    moreMenuItems(cmp, index)
                  );
                })}
              </ul>
            </div>
          </React.Fragment>
        ) : props.repNotificationsOnly && !repNotificationAccessibleMenuItems.includes(esComp.displayName) ? (
          <a style={{ cursor: "not-allowed" }}>
            <Tooltip content={REP_NOTIFICATION_NOT_ALLOWED_TOOLTIP} paddingBottom={20} direction="left">
              {esComp.displayName}
              <i className="fa fa-ellipsis-h" style={{ color: "rgb(74, 226, 226)", cursor: "not-allowed" }} />
            </Tooltip>
          </a>
        ) : (
          <Link
            to={
              props.repNotificationsOnly && !repNotificationAccessibleMenuItems.includes(esComp.displayName)
                ? "/setupPage?repNotificationDisable=true"
                : esComp.url
            }
          >
            <img src={process.env.PUBLIC_URL + "/images/" + esComp.imageSource} />
            {esComp.displayName}
          </Link>
        )}
      </li>
    );
  };

  return (
    <div className="col-md-3 left_col" style={{ backgroundColor: "white" }}>
      <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
        <div className="menu_section">
          <div className="menu-trigger"></div>
          <ul className="nav side-menu">
            {emailServicecompwithurl.map((esComp, index) => {
              return esComp.adminWrapper ? (
                <AdminOnly key={index} isSaOnly={esComp.isSaOnly} actionType={esComp.actionType}>
                  {" "}
                  {mainMenuItems(esComp, index)}
                </AdminOnly>
              ) : (
                mainMenuItems(esComp, index)
              );
            })}
          </ul>
          {props.displayEmailStats ? <EmailStats collapseEmailStats={props.collapseEmailStats} /> : null}
        </div>
      </div>
    </div>
  );
}
export default withRouter(EsSideMenu);
